export const routes = [
    {
        path: '/tasks',
        name: 'tasks.browse',
        component: () => import(/* webpackChunkName: "TasksBrowse" */ '@/views/app/TasksBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/tasks/:id/clone',
        name: 'tasks.clone',
        component: () => import(/* webpackChunkName: "TasksActions" */ '@/views/app/TasksActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/tasks/create',
        name: 'tasks.create',
        component: () => import(/* webpackChunkName: "TasksActions" */ '@/views/app/TasksActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/tasks/:id/edit',
        name: 'tasks.edit',
        component: () => import(/* webpackChunkName: "TasksActions" */ '@/views/app/TasksActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/tasks/:id/delete',
        name: 'tasks.delete',
        component: () => import(/* webpackChunkName: "TasksActions" */ '@/views/app/TasksActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/tasks/:id/show',
        name: 'tasks.show',
        component: () => import(/* webpackChunkName: "TasksActions" */ '@/views/app/TasksActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]